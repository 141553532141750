<template>
  <div id="create">
    <mu-container>
      <mu-form
        :model="form"
        class="mu-demo-form"
        label-position="left"
        label-width="170"
      >
        <mu-form-item prop="input" label="应用名称" :rules="inputRules">
          <mu-text-field v-model="form.name"></mu-text-field>
        </mu-form-item>

        <mu-form-item prop="switch" label="是否不使用查看权限密码">
          <mu-switch v-model="form.public"></mu-switch>
        </mu-form-item>

        <mu-form-item label="查看权限密码" prop="password" :rules="inputRules">
          <mu-text-field
            :disabled="form.public"
            type="password"
            v-model="form.password"
            prop="password"
          ></mu-text-field>
        </mu-form-item>

        <mu-form-item prop="switch" label="是否不使用控制权限密码">
          <mu-switch v-model="form.usecontrolpwd"></mu-switch>
        </mu-form-item>

        <mu-form-item label="控制权限密码" prop="password" :rules="inputRules">
          <mu-text-field
            :disabled="form.usecontrolpwd"
            type="password"
            v-model="form.controlpassword"
            prop="password"
          ></mu-text-field>
        </mu-form-item>
      </mu-form>
    </mu-container>

    <mu-button
      color="primary"
      style="float: right; margin-right: 30px; margin-top: 10px"
      @click="define"
      >创建</mu-button
    >
    <mu-button
      color="Red"
      style="float: right; margin-right: 30px; margin-top: 10px"
      @click="close"
      >取消</mu-button
    >
  </div>
</template>

<script>
import { dynalt_Create } from "../network/dynalt.js";

export default {
  props: {
    lyoption: {
      type: Object,
      default: () => {
        return {};
      },
    },
    layerid: {
      type: String,
      default: "",
    },
    success: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        name: "",
        public: true, // 是否不使用查看权限密码
        password: "",
        usecontrolpwd: true, // 是否不使用控制权限密码
        controlpassword: '', // 控制权限密码
        release: false,
        config: {
          nav: {
            enable: false,
            position: "top",
            float: "outside",
            background: "#fff",
            color: "#333",
            checkColor: "#4c84ff",
            transition: "none",
          },
          board: {
            width: "1600",
            height: "900",
            adaptive: true,
          },
          canvas: []
        }
      },

      inputRules: [{ validate: (val) => !!val, message: "不能为空" }],
    };
  },
  methods: {
    close() {
      this.$layer.close(this.layerid);
    },
    define() {
      if (this.form.name == "") {
        this.$toast.error("组态名称为空");
        return;
      }

      if (this.form.public == false && this.form.password == "") {
        this.$toast.error("密码为空");
        return;
      }

      dynalt_Create(this.form)
        .then(res => {
          this.$toast.success("创建成功");
          this.$layer.close(this.layerid);
          this.success();
        })
        .catch((err) => {
          this.$toast.error("创建失败");
        });
    },
  },
};
</script>

<style lang="stylus" >
#create {
  width: 100%;
  height: 100%;

  padding: 20px;
}
</style>
